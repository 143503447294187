<template>
  <div class="qiqb-table">
    <div class="qiqb-table-item">
      <div class="table-operator" style="display: flex;justify-content: space-between;align-items: center;">
        <div class="table-operator-title">{{ this.$route.name }}</div>
        <a-button type="primary" @click="add">添加</a-button>
      </div>
      <div class="table-item">
        <a-table :columns="columns" :data-source="item" :pagination="total" size="middle" @change="next">
          <span slot="action" slot-scope="text, record">
            <a-popconfirm title="确认删除？" @confirm="del(record)" v-if="record.pid != 0">
              <a-icon slot="icon" type="question-circle-o" style="color: red" />
              <a style="color: red">删除</a>
            </a-popconfirm>
            <a @click="edit(record)" class="ml-2">编辑</a>
            <a @click="lookDtl(record)" class="ml-2">详情</a>
          </span>

        </a-table>
      </div>
    </div>
    <a-modal :title="this.$route.name" :width="640" :visible="show" @ok="okedit" @cancel="show = false">
      <div class="flex-s">
        <div style="white-space: nowrap;width: 200px;">ID:</div>
        <a-input v-model="forms.configId" placeholder="请输入计算报告项目配置id" />
      </div>
      <div class="flex-s mt-1">
        <div class="" style="white-space: nowrap;width: 200px;">标题：</div>
        <a-input v-model="forms.title" placeholder="请输入" />
      </div>
      <div class="flex-s mt-1">
        <div style="white-space: nowrap;width: 200px;">前端计算逻辑:</div>
        <a-input v-model="forms.sqlLogic" placeholder="请输入前端计算逻辑" />
      </div>
      <div class="flex-s mt-1">
        <div style="white-space: nowrap;width: 200px;">计算时长:</div>
        <a-input type="number" v-model="forms.timeSpan" placeholder="请输入计算时长" />
      </div>
      <div class="flex-s mt-1">
        <div style="white-space: nowrap;width: 200px;">计算时间间隔:</div>
        <a-input v-model="forms.timeSplit" type="number" placeholder="请输入计算时间间隔" />
      </div>
      <div class="flex-s mt-1">
        <div style="white-space: nowrap;width: 200px;">前端回传数据参数:</div>
        <a-input v-model="forms.callback" placeholder="请输入前端回传数据参数" />
      </div>
      <div class="flex-s mt-1">
        <div style="white-space: nowrap;width: 200px;">最终计算逻辑js表达式:</div>
        <a-input v-model="forms.finalLogic" placeholder="请输入最终计算逻辑js表达式" />
      </div>
      <div  class="flex-s mt-1">
        <div style="white-space: nowrap;width: 200px;">数据展示表达式:</div>
        <a-input v-model="forms.viewLogic" placeholder="请输入数据展示表达式" />
      </div>
    </a-modal>
    <a-modal :title="this.$route.name" :width="640" :visible="look" @ok="look = false" @cancel="look = false">
      <div class="flex-s">
        <div style="white-space: nowrap;width: 200px;">ID:</div>
        <a-input v-model="dtl.configId" disabled placeholder="请输入计算报告项目配置id" />
      </div>
      <div class="flex-s mt-1">
        <div class="" style="white-space: nowrap;width: 200px;">标题：</div>
        <a-input v-model="dtl.title" disabled placeholder="请输入" />
      </div>
      <div class="flex-s mt-1">
        <div style="white-space: nowrap;width: 200px;">前端计算逻辑:</div>
        <a-input v-model="dtl.sqlLogic" disabled placeholder="请输入前端计算逻辑" />
      </div>
      <div class="flex-s mt-1">
        <div style="white-space: nowrap;width: 200px;">计算时长:</div>
        <a-input type="number" v-model="dtl.timeSpan" disabled placeholder="请输入计算时长" />
      </div>
      <div class="flex-s mt-1">
        <div style="white-space: nowrap;width: 200px;">计算时间间隔:</div>
        <a-input v-model="dtl.timeSplit" disabled type="number" placeholder="请输入计算时间间隔" />
      </div>
      <div class="flex-s mt-1">
        <div style="white-space: nowrap;width: 200px;">前端回传数据参数:</div>
        <a-input v-model="dtl.callback" disabled placeholder="请输入前端回传数据参数" />
      </div>
      <div class="flex-s mt-1">
        <div style="white-space: nowrap;width: 200px;">最终计算逻辑js表达式:</div>
        <a-input v-model="dtl.finalLogic" disabled placeholder="请输入最终计算逻辑js表达式" />
      </div>
      <div  class="flex-s mt-1">
        <div style="white-space: nowrap;width: 200px;">数据展示表达式:</div>
        <a-input v-model="dtl.viewLogic" disabled placeholder="请输入数据展示表达式" />
      </div>
    </a-modal>
    <add ref="add" @okdtl="ok" />
  </div>
</template>

<script>
import add from "./modules/add"
import { itemlist, itemadd, edititem,delitem } from "../../../api/index.js"

export default {
  components: {
    add
  },
  methods: {
    async more(e) {
      // this.queryParam.page = e.current
      this.queryParam.size = 20
      let res = await itemlist(this.queryParam);
      // if (res.status == 200) {
      this.item = res.records;
      this.total = res.total;
      // this.page.current = res.current;
      // }
    },
    change_time(e, str) {
      this.queryParam.time = str
    },
    async okedit() {
      let res = await edititem(this.forms)
      if (res.status == 200) {
        this.more({ current: 1 })
        this.show = false
      }
    },
    lookDtl(record){
      this.dtl = {
        id:record.id,
        configId: record.configId,
        title: record.title,
        sqlLogic: record.sqlLogic,
        timeSpan: record.timeSpan,
        timeSplit: record.timeSplit,
        callback: record.callback,
        finalLogic: record.finalLogic,
        viewLogic: record.viewLogic
      }
      this.look=true
    },
    edit(record) {
      this.forms = {
        id:record.id,
        configId: record.configId,
        title: record.title,
        sqlLogic: record.sqlLogic,
        timeSpan: record.timeSpan,
        timeSplit: record.timeSplit,
        callback: record.callback,
        finalLogic: record.finalLogic,
        viewLogic: record.viewLogic
      }
      this.show = true
    },
    async ok(e) {
      let res = await itemadd(e)
      if (res.status == 200) {
        this.more({ current: 1 })
      }
    },
    async del(e) {
      let res=await delitem({id:e.id})
      if (res.status == 200) {
        this.more({ current: 1 })
      }
    },
    add(){
      this.$refs.add.init()
    },
    next(){
      this.queryParam.page++
      this.more()
    }
  },
  mounted() {
    this.more({ current: 1, id: this.$route.query.id });
  },

  data() {
    return {
      id: 0,
      queryParam: {},
      page: { size: "10",  current: 0 },
      columns: [
        { title: '项目配置id', width: 100,  dataIndex: 'configId' ,ellipsis: true, align: "center"},
        { title: "标题", dataIndex: "title" , align: "center"},
        { title: "计算市场", dataIndex: "timeSpan", align: "center"},
        { title: "计算时间间隔", dataIndex: "timeSplit" , align: "center"},
        { title: "操作",scopedSlots: { customRender: "action" }, align: "center" },
      ],
      item: [],
      look:false,
      forms: {
        id:'',
        configId: '',
        title: '',
        sqlLogic: '',
        timeSpan: '',
        timeSplit: '',
        callback: '',
        finalLogic: '',
        viewLogic: ''
      },
      dtl:{},
      show:false,
      total:''
    };
  }
};
</script>

<style>
.log span {
  display: flex;
}
</style>